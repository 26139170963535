import { createAuth0 } from '@auth0/auth0-vue';
import { ref } from 'vue';

export const auth0 = createAuth0({
  domain: import.meta.env.VITE_AUTH0_DOMAIN,
  clientId: import.meta.env.VITE_AUTH0_CLIENT_ID,
  cacheLocation: 'localstorage',
  useRefreshTokens: true,
  authorizationParams: {
    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
    redirect_uri: import.meta.env.VITE_AUTH0_CALLBACK_URL,
  },
});

const tokenCache = ref(null);
let tokenPromise = null;

const RETRY_ATTEMPTS = 2;
const RETRY_DELAY = 500;
const TOKEN_EXPIRY_BUFFER = 60000; // 1 minute buffer before actual expiration

export class AuthError extends Error {
  constructor(message, originalError) {
    super(message);
    this.name = 'AuthError';
    this.originalError = originalError;
  }
}

const wait = (ms) => new Promise(resolve => setTimeout(resolve, ms));

const isTokenExpired = (cache) => {
  if (!cache) return true;
  return Date.now() >= cache.expiresAt - TOKEN_EXPIRY_BUFFER;
};

const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(atob(base64).split('').map(c => {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));
    return JSON.parse(jsonPayload);
  } catch (error) {
    throw new AuthError('Failed to parse JWT token', error);
  }
};

export const getAuthToken = async () => {
  // Return cached token if it's still valid
  if (tokenCache.value && !isTokenExpired(tokenCache.value)) {
    return tokenCache.value.token;
  }

  // If there's an ongoing token request, wait for it
  if (tokenPromise) {
    try {
      return await tokenPromise;
    } catch {
      tokenPromise = null;
    }
  }

  // Create new token request
  tokenPromise = (async () => {
    let attempts = 0;
    
    while (attempts < RETRY_ATTEMPTS) {
      try {
        if (!auth0.isAuthenticated.value) {
          await wait(RETRY_DELAY);
          attempts++;
          continue;
        }

        const token = await auth0.getAccessTokenSilently();
        const decoded = parseJwt(token);
        
        tokenCache.value = {
          token,
          expiresAt: decoded.exp * 1000, // Convert to milliseconds
        };

        return token;
      } catch (error) {
        attempts++;
        if (attempts === RETRY_ATTEMPTS) {
          throw new AuthError(
            'Failed to get authentication token after multiple attempts',
            error
          );
        }
        await wait(RETRY_DELAY * attempts); // Exponential backoff
      }
    }

    throw new AuthError('Authentication failed: Max retry attempts exceeded');
  })();

  try {
    const token = await tokenPromise;
    return token;
  } finally {
    tokenPromise = null;
  }
};

// Optional: Add a method to force token refresh
export const refreshAuthToken = async () => {
  tokenCache.value = null;
  tokenPromise = null;
  return getAuthToken();
};
