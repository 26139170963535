// src/config/sentry.ts
import * as Sentry from "@sentry/vue";
import type { Router } from 'vue-router';


const dns =  "https://121003d477f582cf624548246b16afa4@o4506938782318592.ingest.us.sentry.io/4508324068327424"
const environment = import.meta.env.MODE as keyof typeof propagationTargets;

const propagationTargets = {
  prod: "app.hellomiinta.com",
  dev: "app.dev.hellomiinta.com",
  localhost: "localhost",
}

const propagationTarget = propagationTargets[environment];

export const initSentry = (app: any, router: Router) => {
  if (!propagationTarget || propagationTarget === "localhost") {
    return;
  }
  Sentry.init({
    app,
    dsn: dns,
    integrations: [
      Sentry.browserTracingIntegration({ router }),
      Sentry.replayIntegration(),
    ],
    tracesSampleRate: 1.0,
    tracePropagationTargets: [propagationTarget, `${propagationTarget}/api`],
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
  });
};