import { defineStore } from 'pinia';
import type { ActivityVersionResponseType, ActivityVersionsType } from '@/pages/TherapistApp/ActivitiesApp/interface';
import { axiosRoot } from '@/utils/axios';
import { urls } from '@/utils/backendUrls';
import type { AxiosResponse } from 'axios';
import { useActivityBlueprintsStore } from './activity_blueprints';

export const useActivityVersionsStore = defineStore('activity_versions', {
    state: () => ({
        versions: [] as ActivityVersionsType[],
        version: {} as ActivityVersionResponseType,
        loadingVersions: false,
    }),
    getters: {
    },
    actions: {
        async fetchVersions(blueprint_id: string) {
            this.loadingVersions = true;
            const params = { blueprint_id };
            const response: AxiosResponse<ActivityVersionsType[]> = await axiosRoot.get(urls.URL_ACTIVITIES_QUERY_GET_VERSIONS, { params });
            this.versions = response.data;
            this.loadingVersions = false
        },
        async fetchVersion(version_id: string) {
            this.loadingVersions = true;
            const params = { version_id };
            const response: AxiosResponse<ActivityVersionResponseType> = await axiosRoot.get(urls.URL_ACTIVITIES_QUERY_GET_VERSION, { params });
            this.version = response.data;
            this.loadingVersions = false
        },
        async sendActivityVersion(versionId: string, clientId: string, therapistId: string | null, chatId: string | null) {
            const activityBlueprintsStore = useActivityBlueprintsStore();
            activityBlueprintsStore.loadingBlueprints = true;
            const params = { version_id: versionId, client_id: clientId, therapist_id: therapistId , chat_id: chatId };
            await axiosRoot.post(urls.URL_ACTIVITIES_COMMAND_SEND_ACTIVITY, params);
            activityBlueprintsStore.loadingBlueprints = false;
        }

    },
});