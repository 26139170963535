// Import necessary types and user store
import type { ActivityCategoryResponseType, ActivityCategoryType } from '@/pages/TherapistApp/ActivitiesApp/interface';
import { useUserStore } from '@/stores/user';
import { useI18n } from '@/utils/i18n';


// Function to map ActivityCategoryResponseType to ActivityCategoryType
export function mapActivityCategoryResponse(categories: ActivityCategoryResponseType[], addAll=true): ActivityCategoryType[] {
  const i18nLocales = {
    es_ES: {
      all: 'Todas',
    },
    en_US: {
      all: 'All',
    },
    ca_ES: {
      all: 'Totes',
    }
  }
  const { i18n } = useI18n(i18nLocales);
  const userStore = useUserStore();
  const languageCode = userStore.userLanguagePreference;

  const categoriesMapped =  categories.map(category => {
    let categoryName = category.default_name;
    if ((category.name as Record<string, string | undefined>)[languageCode]) {
      categoryName = (category.name as Record<string, string | undefined>)[languageCode] || category.default_name;
    }

    return {
      value: category.id.toString(),
      text: categoryName,
      color: 'primary' 
    };
  });
  if (addAll) {
    categoriesMapped.unshift({
      value: 'all',
      text: i18n('all'),
      color: 'primary'
    });
  }


  return categoriesMapped;

}