<template>
  <div>
    <!-- Offcanvas Component -->
    <div
      class="offcanvas offcanvas-end"
      tabindex="-1"
      :id="id"
      ref="offcanvas"
      aria-labelledby="offcanvasLabel"
    >
      <div class="close-button ms-2 mt-2">
        <button type="button" class="btn-close" @click="hideOffcanvas"></button>
      </div>
      <div class="offcanvas-header" v-if="title">
        <h3 class="offcanvas-title" id="offcanvasLabel">{{ title }}</h3>
        <slot name="header"></slot>
      </div>
      <div class="offcanvas-body">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, onMounted, onUnmounted, watch, defineProps, defineEmits } from "vue";
import { Offcanvas } from "bootstrap";
import { useOffcanvasStore, type OffcanvasVisibility } from "@/stores/offcanvas";

const offcanvasStore = useOffcanvasStore();

const props = defineProps<{
  title?: string;
  id: OffcanvasVisibility;
  isVisible: boolean;
}>();

const emit = defineEmits<{
  (e: 'update:isVisible', value: boolean): void;
}>();

const offcanvas = ref<HTMLElement | null>(null);
let offcanvasInstance: Offcanvas | null = null;

const hideOffcanvas = () => {
  if (offcanvasInstance){
    offcanvasInstance.hide();
    offcanvas.value?.addEventListener('hidden.bs.offcanvas', () => {
      offcanvasStore.toggleOffcanvas(props.id);
    });
  }
};

let isMouseDownInsideOffcanvas = false;

const handleMouseDown = (event: MouseEvent) => {
  if (offcanvas.value && offcanvas.value.contains(event.target as Node)) {
    isMouseDownInsideOffcanvas = true;
  } else {
    isMouseDownInsideOffcanvas = false;
  }
};

const handleMouseUp = (event: MouseEvent) => {
  if (!isMouseDownInsideOffcanvas && offcanvas.value && !offcanvas.value.contains(event.target as Node)) {
    hideOffcanvas();
  }
  isMouseDownInsideOffcanvas = false;
};



onMounted(() => {
  if (offcanvas.value) {
    offcanvasInstance = Offcanvas.getOrCreateInstance(offcanvas.value);

    offcanvas.value.addEventListener('hidden.bs.offcanvas', () => {
      emit("update:isVisible", false);
    });

    offcanvas.value.addEventListener('shown.bs.offcanvas', () => {
      emit("update:isVisible", true);
    });

    if (props.isVisible) {
      offcanvasInstance.show();
    }
    document.addEventListener('mousedown', handleMouseDown);
    document.addEventListener('mouseup', handleMouseUp);
  }
});

onUnmounted(() => {
  hideOffcanvas()
  document.removeEventListener('mousedown', handleMouseDown);
  document.removeEventListener('mouseup', handleMouseUp);
});

watch(
  () => props.isVisible,
  (newVal) => {
    if (offcanvasInstance) {
      hideOffcanvas()
      newVal ? offcanvasInstance.show() : offcanvasInstance.hide();
    }
  }
);
</script>

<style scoped lang="scss">
.offcanvas {
  width: 600px;
  @media screen and (max-width: 768px) {
    width: 100%;
    
  }
  &-body {
    padding: 0px;
    margin: 0px;
    width: 100%;
  }
  &-header {
    display: flex;
    justify-content: space-between;
  }
}

</style>