<template>
  <TruncatedText :text="props.text" width="17px">
      <i class="bi bi-info-circle"></i>
  </TruncatedText>
</template>

<script setup lang="ts">
import TruncatedText from '@/components/atoms/TruncatedText.vue';
import { defineProps } from 'vue';

const props = defineProps<{
  text: string,
}>()
</script>

<style scoped lang="scss">
</style>