import { defineStore } from "pinia";


export type OffcanvasVisibility = 'first' | 'second' | 'blueprintVersions' | 'createBlueprint' | 'viewBlueprint';

export const useOffcanvasStore = defineStore({
    id: "offcanvas",
    state: () => ({
        offcanvasVisibility: {
            first: false,
            second: false,
            blueprintVersions: false,
            createBlueprint: false,
            viewBlueprint: false,
        },
    }),
    actions: {
        toggleOffcanvas(key: keyof typeof this.offcanvasVisibility) {
            this.offcanvasVisibility[key] = !this.offcanvasVisibility[key];
        },
        closeAll() {
            for (const key in this.offcanvasVisibility) {
                const keys = Object.keys(this.offcanvasVisibility) as Array<keyof typeof this.offcanvasVisibility>;
                for (const key of keys) {
                    this.offcanvasVisibility[key] = false;
                }
            }
        },
    },
});
