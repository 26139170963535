import { defineStore } from 'pinia'


export type NotificationType = 'info' | 'success' | 'warning' | 'error';
interface BaseNotificationSchema {
    message: string;
    id?: string ;
    type?: NotificationType;
    show?: boolean;
}

interface NotificationSchema extends BaseNotificationSchema{
    id:  string;
    show: boolean;
}

interface State {
  maxNotifications: number
  notifications: NotificationSchema[],
}

export const useNotificationsStore = defineStore('notifications', {
  state: ():State  => ({
    maxNotifications: 5,
    notifications: [],
  }),
  actions: {
    async addNotification(message: string, type: NotificationType='info'){ 
      const id =  Math.random().toString(36).substr(2, 9);
      const show = true;
      const newNotification: NotificationSchema = { message, type, id, show };

      if (this.notifications.length >= this.maxNotifications) {
          this.notifications.shift();
      }
      this.notifications.push(newNotification);
  },
    async hideNotification(id: number | string){
        const index = this.notifications.findIndex((notification) => notification.id === id);
        this.notifications[index].show = false;
    }
  }
})
