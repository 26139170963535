<template>
  <div 
    class="text-truncate"
    :class="{ 'single-line': props.width && !props.maxHeight, 'multi-line': props.width && props.maxHeight }"
    :style="{ maxWidth: props.width || '', maxHeight: props.maxHeight || '' }"
    v-bind="tooltipAttributes"
  >
    <slot></slot>
    <span v-if="props.showText">{{ props.text }}</span>
  </div>
</template>

<script setup lang="ts">
import { onMounted, withDefaults, computed } from 'vue';
import { Tooltip } from 'bootstrap';

interface InputProps {
  text?: string;
  width?: string;
  maxHeight?: string;
  showText?: boolean;
  enableTooltip?: boolean;
}

const props = withDefaults(defineProps<InputProps>(), {
  text: '',
  width: '',
  maxHeight: '',
  showText: true,
  enableTooltip: true,
});

// Tooltip attributes
const tooltipAttributes = computed(() => {
  if (props.enableTooltip) {
    return {
      'data-bs-toggle': 'tooltip',
      'data-bs-placement': 'top',
      'data-bs-html': 'true',
      title: props.text,
    };
  }
  return {};
});

onMounted(() => {
  if (props.enableTooltip) {
    const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
    tooltipTriggerList.forEach((tooltipTriggerEl) => {
      new Tooltip(tooltipTriggerEl);
    });
  }
});
</script>

<style scoped lang="scss">
.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;

  &.single-line {
    white-space: nowrap;
  }

  &.multi-line {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: unset; /* Remove line clamping for wrapping */
  }
}
</style>
