<template>
  <OffCanvas
    :id="props.id"
    :isVisible="props.isVisible"
  >
    <template #header>
    </template>

    <div class="loading" v-if="activityVersionsStore.loadingVersions">
      <LoadingSpinner />

    </div>
    <div class="loaded" v-else>
      <div class="data">
        <FormCreateActivity
          :disabled="true"
          :title="activityVersionsStore.version.title"
          :description="activityVersionsStore.version.description"
          :language_code="activityVersionsStore.version.languageCode"
          :references="activityVersionsStore.version.references"
          :categories="activityVersionsStore.version.categories"
          :text="activityVersionsStore.version.text"
          :media="activityVersionsStore.version.media"
        />
      </div>      
    </div>

  </OffCanvas>
</template>

<script setup lang="ts">
import OffCanvas from "@/components/atoms/OffCanvas.vue";
import { useActivityVersionsStore } from "@/stores/activities/activity_versions";
import LoadingSpinner from "@/components/atoms/LoadingSpinner.vue";
import FormCreateActivity from "../CreateActivity/FormCreateActivity.vue";

const activityVersionsStore = useActivityVersionsStore();

const props = defineProps<{
id: string;
isVisible: boolean;
}>();




</script>