<template>
  <div class="card-container">
    <div class="geometrics">
      <div class="circle"></div>

      <div class="vertical-line">
    </div>

    </div>
    <div class="version-card">
      <BaseCard
        clickable
        @click="openVersion(props.id)"
      >
        <div class="d-flex">
          <div class="card-image">
            <img :src="img" alt="Card image cap"/>
          </div>
          <div class="card-body">
            <h4><TruncatedText :text="props.title"></TruncatedText></h4>
            <p class="card-text">{{ i18n('createdBy') }} {{ props.createdBy }}</p>
            <div class="card-lang-date">
              <p class="card-text">{{ i18n(`${props.language}`) }}</p>
              <p class="card-text">{{ dateFormated }}</p>
            </div>
          </div>
        </div>
      </BaseCard>
    </div>
  </div>
</template>

<script setup lang="ts">
import publicImages from '@/assets/publicImages';
import BaseCard from '@/components/atoms/BaseCard.vue';
import TruncatedText from '@/components/atoms/TruncatedText.vue';
import { computed } from 'vue';
import { useI18n } from "@/utils/i18n";

const emit = defineEmits(['openVersion']);
const props = defineProps<{
  title: string;
  image?: string
  id: string;
  language: string;
  createdBy: string;
  createdAt: string;
}>();

const img = computed(() => props.image ? props.image : publicImages.logoMiinta);
const dateFormated = computed(() => {
  const date = new Date(props.createdAt);
  return date.toLocaleDateString();
});


const openVersion = (id: string) => {
    emit('openVersion', id);
}
const i18nLocales = {
  es_ES: {
    createdBy: 'Creado por: ',
    en_US: 'Inglés',
    es_ES: 'Español',
    ca_ES: 'Catalán',
  },
  en_US: {
    createdBy: 'Created by: ',
    en_US: 'English',
    es_ES: 'Spanish',
    ca_ES: 'Catalan',
  },
  ca_ES: {
    createdBy: 'Creat per: ',
    en_US: 'Anglès',
    es_ES: 'Espanyol',
    ca_ES: 'Català',
  },
}
const { i18n } = useI18n(i18nLocales);
</script>

<style scoped lang="scss">
.geometrics {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.circle {
  margin-left: -4px;
  width: 7px;
  height: 7px;
  background-color: $grey-400;
  border-radius: 50%;
}
.card-container {
  display: flex;
  align-items: flex-start;
  height: 140px;
  padding: 5px 0px;
  margin: 0px 0px 10px 0px;
  @media screen and (max-width: 768px) {
    height: 120px;
    
  }
}

.vertical-line {
  margin: 3px 15px 0px 10px;
  padding: 0.9px;
  height: 125px;
  background-color: $grey-400;
  color: white;
  @media screen and (max-width: 768px) {
    height: 105px;
  }
}
.version-card {
  width: 90%;
  height: 100%;
  padding: 0px 5px
}

h4 {
  margin: 0px 0px 5px 0px;
  padding: 0px;
}
.card{
  &-image {
    margin-left: 5px;
    width: 50px;
    height: 50px;
    @media screen and (max-width: 768px) {
      width: 40px;
      height: 40px;
      
    }
    
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  &-body {
    padding: 0px 15px;
    width: 100%;
  }
  &-text {
    @extend .text-md;
    color: $grey-150;
    padding-bottom: 0px;
    margin-bottom: 0px
  }
  &-title{
    margin-top: 0px;
    @extend h2;
  }
  &-lang-date {
    display: flex;
    justify-content: space-between;
  }
}
</style>