import { defineStore } from 'pinia';
import type { ActivityBlueprintCardType, CommandCreateActivityBlueprintType } from '@/pages/TherapistApp/ActivitiesApp/interface';
import { axiosRoot } from '@/utils/axios';
import { urls } from '@/utils/backendUrls';
import type { AxiosResponse } from 'axios';
import { mapActivityCategoryResponse } from './utils/category_utils';
import { useUserStore } from '../user';
import { useSelectedChatDetailsStore } from '../chatApp/selected_chat_details';

type OffsetLimit = {
    offset: number,
    limit: number,
    allLoaded: boolean,
}
const DEFAULT_LIMIT = 6;

type filterBlueprintsType = {
    categories: string[] | null,
    liked: boolean | null,
    bookmarked: boolean | null,
    therapist_id: string | null,
    client_id?: string | null;
    offset?: number;
    limit?: number;
};

export const useActivityBlueprintsStore = defineStore('activity_blueprints', {
    state: () => ({
        offsetLimitBlueprints: { offset: 0, limit: DEFAULT_LIMIT, allLoaded:false } as OffsetLimit, 
        blueprints: [] as ActivityBlueprintCardType[],
        loadingBlueprints: false,
        loadingCreatingBlueprint: false,
        selectedBlueprint: null as ActivityBlueprintCardType | null,
        filtersBlueprints: {
            categories: null,
            liked: null,
            bookmarked: null,
            therapist_id: null,
        } as filterBlueprintsType
    }),
    getters: {
    },
    actions: {
        async fetchBlueprints() {
            this.loadingBlueprints = true;
            const userStore = useUserStore();
            const selectedChatDetailsStore = useSelectedChatDetailsStore();
            const client_id = selectedChatDetailsStore.chatDetails?.clientId;
            const therapist_id = this.filtersBlueprints.therapist_id || userStore.userInfo?.therapist_id;
            const params: filterBlueprintsType = {
                therapist_id: therapist_id,
                categories: this.filtersBlueprints.categories,
                liked: this.filtersBlueprints.liked,
                bookmarked: this.filtersBlueprints.bookmarked,
                offset: this.offsetLimitBlueprints.offset,
                limit: this.offsetLimitBlueprints.limit,
            };
            if (client_id) {
                params['client_id'] = client_id;
            }

            const response: AxiosResponse<ActivityBlueprintCardType[]> = await axiosRoot.get(urls.URL_ACTIVITIES_QUERY_GET_BLUEPRINTS, { params });
            if (response.data.length < this.offsetLimitBlueprints.limit) {
                this.offsetLimitBlueprints.allLoaded = true;
            }
            this.blueprints = [...this.blueprints, ...response.data];
            if (response.data && response.data.length > 0) {

                response.data.forEach(blueprint => {
                    if (blueprint.categories) {
                        blueprint.categories = mapActivityCategoryResponse(blueprint.categories, false);
                    }
                });
            }
            this.loadingBlueprints = false;
        },
        selectBlueprint(blueprintId: string) {
            this.selectedBlueprint = this.blueprints.find(blueprint => blueprint.id === blueprintId) || null;
        },
        setFilter(filter: keyof filterBlueprintsType, value: string | boolean | string[] |null) {
            this.filtersBlueprints = { ...this.filtersBlueprints, [filter]: value };
        },
        resetFilters() {
            this.filtersBlueprints = {
                categories: null,
                liked: null,
                bookmarked: null,
                therapist_id: null,
            };
        },
        setSelectedBlueprint(id: string | null) {
            if (this.blueprints.length > 0) {
                this.blueprints.forEach(blueprint => {
                    blueprint.selected = blueprint.id === id;
                });
            }
        },
        async createBlueprint(blueprint: CommandCreateActivityBlueprintType) {
            const userStore = useUserStore();
            blueprint.therapist_id = userStore.userInfo?.therapist_id || '';
            this.loadingCreatingBlueprint = true;

            const data = new FormData();
            data.append('therapist_id', blueprint.therapist_id);
            data.append('default_name', blueprint.title);
            data.append('description', blueprint.description);
            data.append('references', blueprint.references);
            data.append('language_code', blueprint.language_code);
            data.append('text', blueprint.text);
            data.append('categories', blueprint.categories.toString());

            if (blueprint.media && blueprint.media.length > 0) {
            blueprint.media.forEach((file) => {
                data.append('media', file);
            });
            }
            await axiosRoot.post(urls.URL_ACTIVITIES_COMMAND_CREATE_BLUEPRINT, data, {
                headers: { 'Content-Type': blueprint.media ? 'multipart/form-data' : 'application/json' },
            }
            );
            this.resetFilters();
            await this.fetchBlueprints();
            this.loadingCreatingBlueprint = false;
        },

        async likeBlueprint(blueprintId: string) {
            const userStore = useUserStore();
            const therapist_id = userStore.userInfo?.therapist_id;
            const blueprint = this.blueprints.find(blueprint => blueprint.id === blueprintId);
            if (!therapist_id || !blueprint) {
                return false;
            }
            blueprint.loading = true;
            blueprint.isLiked = !blueprint.isLiked;
            const like = blueprint.isLiked;
            await axiosRoot.post(urls.URL_ACTIVITIES_COMMAND_LIKE_BLUEPRINT, { blueprint_id: blueprintId, therapist_id, like });
            blueprint.loading = false;
            return blueprint.isLiked;
        },

        async bookmarkBlueprint(blueprintId: string) {
            const userStore = useUserStore();
            const therapist_id = userStore.userInfo?.therapist_id;
            const blueprint = this.blueprints.find(blueprint => blueprint.id === blueprintId);
            if (!therapist_id || !blueprint) {
                return;
            }
            blueprint.loading = true;
            blueprint.isBookmarked = !blueprint.isBookmarked;
            const bookmark = blueprint.isBookmarked;
            await axiosRoot.post(urls.URL_ACTIVITIES_COMMAND_BOOKMARK_BLUEPRINT, { blueprint_id: blueprintId, therapist_id, bookmark });
            blueprint.loading = false;
            return blueprint.isBookmarked;
        },
        async addOffset() {
            this.offsetLimitBlueprints.offset += DEFAULT_LIMIT;
        },
        async resetOffset() {
            this.offsetLimitBlueprints.allLoaded = false;
            this.offsetLimitBlueprints.offset = 0;
            this.blueprints = [];
        },
    },
});