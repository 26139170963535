/*
Meaning:
- UTC: Coordinated Universal Time
- GMT: Greenwich Mean Time
- CET: Central European Time

Our Database Stores time in UTC, so when a new row is inserted, the value is -2h from Spain.
When we retrieve the data, we need to add 2h to get the correct time in Spain.

Since we know we have the data in UTC, and the timezoneOffset is -120 minutes,
We need to add the offset to get the correct time in Spain.
*/
const yesterday = new Date();
yesterday.setDate(yesterday.getDate() - 1);
yesterday.setHours(yesterday.getHours() - 2);

export const remainingHours = (lastMessageTime) => {
  if (!lastMessageTime) return 0;
  const lastMessageDate = new Date(lastMessageTime || '');
  const diff =  lastMessageDate.getTime() - yesterday.getTime()
  const hours = Math.ceil(diff / (1000 * 60 * 60));
  const hoursCleaned = hours >= 24 ? 24 : hours < 0 ? 0 : hours;
  return hoursCleaned
};

export const formatDate = (dateStr, include_hour=true) => {
  let date = new Date(dateStr);
  let hours = date.getHours();
  let minutes = date.getMinutes();
  let day = date.getDate();
  let month = date.getMonth() + 1;  // Months are zero-indexed
  let year = date.getFullYear().toString().slice(-2);  // Get last two digits of year
  let formattedDate = `${day}/${month}/${year} ${hours}h${minutes}`;
  if (!include_hour) {
    formattedDate = `${day}/${month}/${year}`;
  }
  return formattedDate;
};


const i18nLocales = {
  'es_ES': {
    'seconds_ago': 'hace unos segundos',
    'minutes_ago': 'hace %d minutos',
    'hours_ago': 'hace %d horas',
    'yesterday': 'Ayer',
    'days_ago': 'hace %d días',
    'months_ago': 'hace %d meses',
    'years_ago': 'hace %d años',
  },
  'en_US': {
    'seconds_ago': 'a few seconds ago',
    'minutes_ago': '%d minutes ago',
    'hours_ago': '%d hours ago',
    'yesterday': 'Yesterday',
    'days_ago': '%d days ago',
    'months_ago': '%d months ago',
    'years_ago': '%d years ago',
  },
  'ca_ES': {
    'seconds_ago': 'fa uns segons',
    'minutes_ago': 'fa %d minuts',
    'hours_ago': 'fa %d hores',
    'yesterday': 'Ahir',
    'days_ago': 'fa %d dies',
    'months_ago': 'fa %d mesos',
    'years_ago': 'fa %d anys',
  }
};


export const timeAgo = (dateAsString, language='es_ES') => {
  const now = new Date();
  const date = new Date(dateAsString);
  const seconds = Math.floor((now.getTime() - date.getTime()) / 1000);

  const locale = i18nLocales[language] || i18nLocales['es_ES'];

  let interval = Math.floor(seconds / 31536000);
  if (interval > 1) {
    return locale['years_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 2592000);
  if (interval > 1) {
    return locale['months_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 86400);
  if (interval > 1) {
    return locale['days_ago'].replace('%d', interval);
  }
  if (interval === 1) {
    return locale['yesterday'];
  }
  interval = Math.floor(seconds / 3600);
  if (interval > 1) {
    return locale['hours_ago'].replace('%d', interval);
  }
  interval = Math.floor(seconds / 60);
  if (interval > 1) {
    return locale['minutes_ago'].replace('%d', interval);
  }
  return locale['seconds_ago'];
};
