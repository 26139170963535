<template>
  <div 
    class="scrollable-div-container" 
    :style="{
      height: containerHeight,
      maxWidth: maxWidth,
      width: width
    }"
  >
    <div class="fixed-top" :class="{ 'shadow': showTopShadow }">
      <slot name="top"></slot>
    </div>
    
    <div 
      ref="scrollableContent"
      class="scrollable-div"
      :style="{ maxHeight: contentHeight }"
      @scroll="handleScroll"
    >
      <slot></slot>
    </div>
    
    <div class="fixed-bottom" :class="{ 'shadow': showBottomShadow }">
      <slot name="bottom"></slot>
    </div>

    <!-- Scroll to top button -->
    <button 
      v-if="showScrollTopButton && isScrolled"
      class="scroll-top-button"
      @click="scrollToTop"
      :style="{ right: scrollButtonOffset }"
    >
      ↑
    </button>
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, computed } from 'vue'

const props = defineProps({
  containerHeight: {
    type: String,
    default: '100%'
  },
  contentHeight: {
    type: String,
    default: '400px'
  },
  width: {
    type: String,
    default: '100%'
  },
  maxWidth: {
    type: String,
    default: '100%'
  },
  showScrollTopButton: {
    type: Boolean,
    default: true
  },
  scrollButtonOffset: {
    type: String,
    default: '1rem'
  },
  shadowThreshold: {
    type: Number,
    default: 20
  }
})

const emit = defineEmits(['scroll', 'scroll-top', 'scroll-bottom'])

// Refs
const scrollableContent = ref(null)
const isScrolled = ref(false)
const showTopShadow = ref(false)
const showBottomShadow = ref(false)

// Methods
const handleScroll = (event) => {
  const element = event.target
  const { scrollTop, scrollHeight, clientHeight } = element
  
  // Update scroll position state
  isScrolled.value = scrollTop > 0
  
  // Update shadows
  showTopShadow.value = scrollTop > props.shadowThreshold
  showBottomShadow.value = scrollHeight - scrollTop - clientHeight > props.shadowThreshold
  
  // Emit scroll event with useful data
  emit('scroll', {
    scrollTop,
    scrollHeight,
    clientHeight,
    scrollPercentage: (scrollTop / (scrollHeight - clientHeight)) * 100,
    isAtTop: scrollTop === 0,
    isAtBottom: Math.ceil(scrollTop + clientHeight) >= scrollHeight
  })
  
  // Emit specific events for top/bottom
  if (scrollTop === 0) {
    emit('scroll-top')
  }
  if (Math.ceil(scrollTop + clientHeight) >= scrollHeight) {
    emit('scroll-bottom')
  }
}

const scrollToTop = () => {
  scrollableContent.value?.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}

// Expose methods to parent
defineExpose({
  scrollToTop,
  scrollTo: (options) => scrollableContent.value?.scrollTo(options),
  getScrollElement: () => scrollableContent.value
})
</script>

<style scoped>
.scrollable-div-container {
  display: flex;
  flex-direction: column;
  position: relative;
  box-sizing: border-box;
  min-width: 0; /* Prevents flex items from overflowing */
  overflow-x: hidden; /* Prevent horizontal scrolling */
  scrollbar-width: none; /* Hide scrollbar for Firefox */
  width: 100%;
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
}
.scrollable-div-container::-webkit-scrollbar {
  display: none;
}


.fixed-top, .fixed-bottom {
  flex-shrink: 0;
  position: relative;
  z-index: 1;
  transition: box-shadow 0.3s ease;
  width: 100%;
  box-sizing: border-box;
}

.fixed-top.shadow {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.fixed-bottom.shadow {
  box-shadow: 0 -2px 4px rgba(0, 0, 0, 0.1);
}

.scrollable-div {
  overflow-y: auto;
  overflow-x: hidden; /* Prevent horizontal scrolling */
  flex-grow: 1;
  width: 100%;
  box-sizing: border-box;
  scrollbar-width: thin;
  scrollbar-color: rgba(0, 0, 0, 0.2) transparent;
}

/* Custom scrollbar styling for webkit browsers */
.scrollable-div::-webkit-scrollbar {
  width: 6px;
}

.scrollable-div::-webkit-scrollbar-track {
  background: transparent;
}

.scrollable-div::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}

.scroll-top-button {
  position: absolute;
  bottom: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background: #fff;
  border: 1px solid #ddd;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 2;
}

.scroll-top-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}
</style>