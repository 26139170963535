import { defineStore } from 'pinia';
import type { ActivityCategoryResponseType, ActivityCategoryType } from '@/pages/TherapistApp/ActivitiesApp/interface';
import { urls } from '@/utils/backendUrls';
import { mapActivityCategoryResponse } from './utils/category_utils';
import type { AxiosResponse } from 'axios';
import { axiosRoot } from '@/utils/axios';


export const useActivityCategoriesStore = defineStore('activity_categories', {
    state: () => ({
        categories: [] as ActivityCategoryType[],
    }),
    getters: {
        categoriesWithoutAll: (state) => () => state.categories.filter(category => category.value !== 'all'),
    },
    actions: {
        async fetchCategories() {
            if (this.categories.length > 0) {
                return;
            }
            const response: AxiosResponse<ActivityCategoryResponseType[]> = await axiosRoot.get(urls.URL_ACTIVITIES_QUERY_GET_CATEGORIES)
            this.categories = mapActivityCategoryResponse(response.data);
        }
    },
});