import { unref } from 'vue'
import { useAuth0 } from '@auth0/auth0-vue'
import type { Router, RouteLocationNormalized } from 'vue-router'
import { getAuthToken } from '@/utils/auth0'

export const setupRouterGuard = (router: Router) => {
  router.beforeEach(async (to: RouteLocationNormalized, from: RouteLocationNormalized) => {
    const { user, logout } = useAuth0()

    // Skip auth for public routes
    if (to.meta.public) {
      return true
    }

    try {
      // Ensure we have a valid token before proceeding
      await getAuthToken()
      
      if (!unref(user)) {
        console.error('User info not available after maximum attempts')
        await logout({ logoutParams: { returnTo: window.location.origin } })
        return true
      }

      const roles = unref(user)?.custom_roles_claim || []
      const isTherapist = roles.includes("Therapist")
      const isClient = !isTherapist

      // Handle root path redirect
      if (to.path === '/') {
        if (isTherapist) {
          return { name: 'therapist-home' }
        } else if (isClient) {
          return { name: 'client-home' }
        } else {
          return { name: '403' }
        }
      }

      // Check route permissions
      const requiresTherapist = to.matched.some(record => record.meta.requiresTherapist)
      const requiresClient = to.matched.some(record => record.meta.requiresClient)

      if (requiresTherapist && !isTherapist) {
        return { name: '403' }
      }

      if (requiresClient && !isClient) {
        return { name: '403' }
      }

      return true
    } catch (error) {
      console.error('Authentication error:', error)
      await logout({ logoutParams: { returnTo: window.location.origin } })
      return true
    }
  })
}
