<template>
  <button 
    class="btn" 
    @click="onClick" 
    :class="classColor"
    @mouseover="isHovered = true" 
    @mouseleave="isHovered = false" 
    @mousedown="isClicked = true" 
    @mouseup="isClicked = false"
  >
    <span v-if="icon" class="icon" :class="!isMobile? 'me-2' : ''">
      <i class="bi" :class="icon"></i>
    </span>
    <span v-if="text && !isMobile">{{ props.text }}</span>
  </button>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue';

const emit = defineEmits(['onClick']);
const props = defineProps<{
  text?: string
  color: 'primary' | 'secondary' | 'orange' | 'green' | 'red' | 'blue' | 'yellow';
  icon?: string
  mobileVersion?: boolean
}>();

const isHovered = ref(false);
const isClicked = ref(false);
const isMobile =computed(() =>window.innerWidth < 768 && props.mobileVersion);


const onClick = () => {
  emit('onClick');
}

const classColor = computed(() => ({
  'btn-primary': props.color === 'primary',
  'btn-secondary': props.color === 'secondary',
  'btn-orange': props.color === 'orange',
  'btn-green': props.color === 'green',
  'btn-red': props.color === 'red',
  'btn-blue': props.color === 'blue',
  'btn-yellow': props.color === 'yellow',
}));
</script>

<style scoped lang="scss">
.btn {
  @extend .text-md--semibold;
  border: none;
  transition: background-color 0.3s;
  padding: 5px 20px;
  height: fit-content;
  border-radius: 25px;
  display: flex;
  align-items: center;
  
  @media (max-width: 576px) {
    padding: 5px 15px;
  }

  &-primary {
    background-color: rgba($primary, 1);
    color: white;
    &:hover {
      background-color: rgba($primary, 0.8);
    }
    &:active {
      background-color: rgba($primary, 0.6);
    }
  }
  &-secondary {
    background-color: rgba($secondary, 1);
    color: $grey-100;
    &:hover {
      background-color: rgba($secondary, 0.8);
    }
    &:active {
      background-color: rgba($secondary, 0.6);
    }
  }
  &-orange {
    background-color: rgba($orange-100, 1);
    color: white;
    &:hover {
      background-color: rgba($orange-100, 0.8);
    }
    &:active {
      background-color: rgba($orange-100, 0.6);
    }
  }
  &-green {
    background-color: rgba($green-100, 1);
    color: white;
    &:hover {
      background-color: rgba($green-100, 0.8);
    }
    &:active {
      background-color: rgba($green-100, 0.6);
    }
  }
  &-red {
    background-color: rgba($red-100, 1);
    color: white;
    &:hover {
      background-color: rgba($red-100, 0.8);
    }
    &:active {
      background-color: rgba($red-100, 0.6);
    }
  }
  &-blue {
    background-color: rgba($blue-100, 1);
    color: white;
    &:hover {
      background-color: rgba($blue-100, 0.8);
    }
    &:active {
      background-color: rgba($blue-100, 0.6);
    }
  }
  &-yellow {
    background-color: rgba($yellow-100, 1);
    color: white;
    &:hover {
      background-color: rgba($yellow-100, 0.8);
    }
    &:active {
      background-color: rgba($yellow-100, 0.6);
    }
  }
  
}
</style>