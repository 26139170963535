<template>
  <div :class="classes">
    <select class="form-select search-rounded" :class="classes" @input="handleInput" :value="setSelected">
      <option v-for="choice in props.choices" :value="choice.value" :key="choice.value">{{ choice.text }}</option>
    </select>
  </div>
</template>

<script setup lang="ts">
import { defineProps, defineEmits, withDefaults, computed } from 'vue';
const emit = defineEmits(['input']);

interface SelectChoices {
  value: string | number;
  text: string;
}
interface SelectProps {
  placeholder?: string;
  choices?: SelectChoices[];
  selected?: string|number;
  size?: 'small' | 'medium' | 'large';
}

const props: SelectProps = withDefaults(defineProps<SelectProps>(), {
  placeholder: "Search",
  size: 'small'
});

const setSelected = computed(() => {
  return props.selected ?? props.choices?.[0]?.value;
});



const classes = computed(() => ({
  'text-sm': props.size === 'small',
  'small-form': props.size === 'small',
  'text-md': props.size === 'medium',
  'form-select-sm': props.size === 'small',
  'form-select-md': props.size === 'small',
  'form-select-lg': props.size === 'medium',
}));

const handleInput = (event: Event) => {
  const target = event.target as HTMLSelectElement;
  emit('input', target.value);
}
</script>

<style scoped lang="scss">
.search-rounded{
  border-radius: 20px;
  padding-left: 15px;
  background-color: $grey-900;
  border: 0px;
  color: $grey-100;
}

</style>