<template>
  <BaseForm 
    :valid="formIsValid"
    @form-valid="onSubmit"
    @form-invalid="onInvalid"
    :form-id="formId"
    class="h-100 w-100"
  >
    <ScrollableDiv
      containerHeight="100%"
      contentHeight="100%"
      width="100%"
      :showScrollTopButton="true"
      scrollButtonOffset="1000"
      :shadowThreshold="10"
    >
      <template #top>
        <div style="margin-right: 40px;">
          <FormTextInput class="text-xxl--semibold ms-3 mb-3 mt-2" 
            :model-value="formState.title"
            :disabled="props.disabled"
            :place-holder="i18n('editTitle')" 
            @input="editForm('title', $event)"
            />
        </div>
        </template>
      <template #default>
        <div class="w-100 ps-3 pe-3">
          <div class="form-group ps-3">
            <label for="references">{{ i18n('Description') }}</label>
            <FormTextInput 
              :disabled="props.disabled"
              :model-value="formState.description" :place-holder="i18n('editDescription')" @input="editForm('description', $event)"/>
          </div>
          <div class="form-group ps-3">
            <label for="references">{{ i18n('References') }}</label>
            <FormTextInput 
              :disabled="props.disabled"
              :model-value="formState.references" :place-holder="i18n('editReferences')" @input="editForm('references', $event)"/>
          </div>
          <div class="form-group ps-3" :class="props.disabled? 'mt-3 mb-3': ''">
            <label for="categories">{{ i18n('Categories') }}</label>
            <FilterTags
              class="w-75"
              v-if="!props.disabled"
              :choices="categoriesFromStore"
              @handleInput="handleCategoryInput"
              :filterVariable="{text: '', value: 'categoriesNewActivity'}"
            />
            <div v-else class="d-flex ms-2 mt-1">
              <BasePill
                v-for="category in props.categories"
                :key="category.id"
                :text="category.default_name"
                color="primary"
                width="500"
                class="me-2"
              />
            </div>
          </div>
          <div class="form-group ps-3" :class="props.disabled? 'mt-3 mb-3': ''">
            <label for="language">{{ i18n('Language') }}</label>
            <SelectInput v-if="!props.disabled"
              :choices="[{text: 'Español', value: 'es_ES'}, {text: 'Inglés', value: 'en_US'}, {text: 'Catalán', value: 'ca_ES'}]"
              :selected="'es_ES'"
              @input="handleLanguageFilter"
            />
            <div v-else class="d-flex ms-2 mt-1">
              <BasePill
                :text="i18n(props.language_code)"
                color="primary"
                width="500"
                class="me-2"
              />
            </div>
          </div>
          <hr/>
          <div class="d-flex flex-column w-100">
          <h4 class="p-2">{{ i18n('Instructions') }}</h4>
          <div class="form-group mb-2 ms-2 h-100 w-100">
            <FormTextInput 
              class="w-100"
              :model-value="formState.text" 
              :place-holder="i18n('editInstructions')" 
              text-area
              @input="editForm('text', $event)"
            />
          </div>
        </div>
        <hr/>
        <div class="d-flex flex-column w-100">
          <h4 class="p-2">{{ i18n('Media') }}</h4>
          <div
            v-if="!props.disabled"
            class="form-group mb-2 ms-2 h-100 w-100">
            <FormFileInput 
              multiple
              @files-changed="editForm('media', $event)"
            />
          </div>
          <div v-else class="d-flex ms-2 mt-1">
            <MediaHolder
              v-for="media in props.media"
              :key="media.id"
              :id="media.id"
              :type="media.type"
              :url="media.url"
            />
          </div>
        </div>
        <hr/>

      </div>
      </template>
      <template #bottom>
        <div class="d-flex justify-content-end p-2 mt-1" v-if="!props.disabled">
          <BaseButton
            :disabled="!formIsValid"
            @click="submitForm"
            class="ml-auto" 
            color="primary" 
            :text="i18n('SaveChanges')"
          />
        </div>
      </template>
    </ScrollableDiv>
  </BaseForm>
</template>

<script setup lang="ts">
import { useI18n } from "@/utils/i18n";
import { computed, onMounted, ref, withDefaults } from 'vue';
import FilterTags from '@/components/molecules/FilterTags/FilterTags.vue';
import SelectInput from "@/components/atoms/SelectInput.vue";
import { useActivityCategoriesStore } from "@/stores/activities/activity_categories";
import BaseForm from "@/components/molecules/BaseForm/BaseForm.vue";
import FormTextInput from "@/components/atoms/inputs/FormTextInput.vue";
import BaseButton from "@/components/atoms/buttons/BaseButton.vue";
import type { ActivityCategoryResponseType, CommandCreateActivityBlueprintType, MediaResponseType } from "../../interface";
import ScrollableDiv from "@/components/atoms/ScrollableDiv.vue";

import FormFileInput from "@/components/atoms/inputs/FormFileInput.vue";
import BasePill from "@/components/atoms/BasePill.vue";
import MediaHolder from "../../MediaHolder/MediaHolder.vue";
const activityCategoriesStore = useActivityCategoriesStore();
const categoriesFromStore = activityCategoriesStore.categoriesWithoutAll();

const emit = defineEmits(['onSubmit'])
const props = withDefaults(defineProps<{
  disabled?: boolean;
  title?: string;
  description?: string;
  references?: string;
  language_code?: string;
  text?: string;
  categories?: ActivityCategoryResponseType[];
  media?: MediaResponseType[];
}>(), {
  disabled: false,
  title: '',
  description: '',
  references: '',
  language_code: 'es_ES',
  text: '',
  categories: () => [],
  media: () => []
});



const handleCategoryInput = (key: string, value: string) => {
  console.log(key, value)
  const valueList = value.slice(1, -1).replace(/'/g, '').split(',')
  formState.value.categories= valueList
}
const handleLanguageFilter = (key: string,) => {
  formState.value.language_code = key
}

const formId = 'create-activity-form'
const validCategory = ref(false)
const selectedCategory = ref(false)
const onSubmit = () => {
  if (!selectedCategory.value) {
    validCategory.value = false
    return
  }
  emit('onSubmit', formState)  
}

const submitForm = () => {
  emit('onSubmit', { ...formState.value })
}
const onInvalid = () => {
  console.log('Form validation failed')
}

const formState = ref<CommandCreateActivityBlueprintType>({
  title: props.title,
  description: props.description,
  references: props.references,
  language_code: props.language_code,
  text: props.text,
  categories: [],
  media: []
})
const formIsValid = computed(() => {
  if (formState.value.categories.length == 0 || formState.value.categories.includes('all')) {
    return false
  }
  return Object.values(formState.value).every((value) => {return value !== ''})
})
const editForm = (key: keyof CommandCreateActivityBlueprintType, value: string | string[] | File[]) => {
  if (Array.isArray(formState.value[key]) && Array.isArray(value)) {
    formState.value[key] = value as any;
  } else if (typeof formState.value[key] === 'string' && typeof value === 'string') {
    formState.value[key as keyof CommandCreateActivityBlueprintType] = value as any;
  }
  if (key === 'media' && value instanceof Object) {
    const files = Array.from(value as unknown as File[]);
    if (files) {
      formState.value[key] = files;
    }
  }

}

const i18nLocales = {
  es_ES: {
    Title: 'Título',
    Description: 'Descripción',
    References: 'Referencias',
    Categories: 'Categorías',
    Language: 'Idioma',
    Media: 'Archivos',
    SaveChanges: 'Guardar Cambios',
    Instructions: 'Instrucciones',
    editTitle: 'Escribe un Título...',
    editDescription: 'Escribe una descripción...',
    editReferences: 'Agrega un referencia...',
    editCategories: 'Escribe un Categorías...',
    editLanguage: 'Escribe un Idioma...',
    editInstructions: 'Escribe las instrucciones aquí...',
    es_ES: 'Español',
    en_US: 'Inglés',
    ca_ES: 'Catalán',
  },
  en_US: {
    Title: 'Title',
    Description: 'Description',
    References: 'References',
    Categories: 'Categories',
    Language: 'Language',
    Media: 'Media',
    SaveChanges: 'Save Changes',
    Instructions: 'Instructions',
    editTitle: 'Edit Title...',
    editDescription: 'Edit Description...',
    editReferences: 'Edit References...',
    editCategories: 'Edit Categories...',
    editLanguage: 'Edit Language...',
    editMedia: 'Edit Media...',
    editInstructions: 'Edit Instructions...',
    es_ES: 'Spanish',
    en_US: 'English',
    ca_ES: 'Catalan',
  },
  ca_ES: {
    Title: 'Títol',
    Description: 'Descripció',
    References: 'Referències',
    Categories: 'Categories',
    Language: 'Idioma',
    Media: 'Arxius',
    SaveChanges: 'Guardar Canvis',
    Instructions: 'Instruccions',
    editTitle: 'Escriu un Títol...',
    editDescription: 'Escriu una descripció...',
    editReferences: 'Afegeix una referència...',
    editCategories: 'Escriu un Categorías...',
    editLanguage: 'Escriu un Idioma...',
    editInstructions: 'Escriu les instruccions aquí...',
    es_ES: 'Espanyol',
    en_US: 'Anglès',
    ca_ES: 'Català',
  },
}
const { i18n } = useI18n(i18nLocales);

onMounted(async() => {
  await activityCategoriesStore.fetchCategories();
});
</script>


<style scoped lang="scss">
h4 {
  color: $grey-150;
  margin-top: 20px;
  margin-bottom: 10px;
}

.form-group {
  margin-bottom: 3px;
  display: flex;
  align-items: center;
  justify-content: left;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 0px !important;
    align-items: flex-start
  }
  & > label {
    @extend .text-md--semibold;
    color: $grey-150;
    min-width: 200px;
    @media screen and (max-width: 768px) {
      padding-left: 12px;
      
    }
  }
  & > input {
    @extend .text-md--thin;
    width: 100%;
    border: none;
    &:not(:placeholder-shown) {
      background-color: transparent;
    }
  }
  & > textarea {
    @extend .text-md--regular;
    width: 100%;
    height: 100%;
    border: none;
    height: 100px;
    padding: 0px;
  }
}
</style>