export const urls = {
  URL_USERMG_QUERY_GET_RESET_PASSWORD_LINK: '/user_management/queries/get-reset-password-link',
  URL_CHAT_QUERY_AVAILABLE_THERAPISTS:'/chat/queries/get-available-therapists',
  URL_CHAT_QUERY_GET_MY_USER_INFO: '/chat/queries/get-my-user-info',
  URL_CHAT_QUERY_GET_CHAT_MESSAGES: '/chat/queries/get-chat-messages',
  URL_CHAT_QUERY_FILTER_SUMMARY_CHATS: '/chat/queries/filter-summary-chats',
  URL_CHAT_QUERY_GET_CLIENT: '/chat/queries/get-client',
  URL_CHAT_COMMAND_ASSIGN_THERAPIST: '/chat/commands/assign-therapist',
  URL_CHAT_COMMAND_MARK_CHAT_AS_READ: '/chat/commands/mark-chat-as-read',
  URL_CHAT_COMMAND_SEND_MESSAGE: '/chat/commands/send-message-to-chat',
  URL_CHAT_COMMAND_SEND_FILE: '/chat/commands/send-file-to-chat',
  URL_FILES_COMMAND_TRANSCRIBE_AUDIO: '/files/commands/transcribe-audio-file',
  URL_FILES_QUERY_GET_FILE: '/files/queries/get-file',
  URL_FORMS_QUERY_GET_FORMS: '/forms/queries/get-forms',
  URL_TEMPLATES_QUERY_GET_TEMPLATES: '/templates/queries/get-templates',
  URL_TEMPLATES_COMMAND_SEND_TEMPLATE: '/templates/commands/send-template',
  URL_CHAT_COMMAND_UPDATE_CLIENT_DESCRIPTION: '/chat/commands/update-client-description',
  URL_CHAT_COMMAND_UPDATE_LANGUAGE: '/chat/commands/update-language',
  URL_CHAT_COMMAND_UPDATE_CLIENT_SETTINGS: '/chat/commands/update-client-settings',
  URL_CHAT_COMMAND_UPDATE_THERAPIST_SETTINGS: '/chat/commands/update-therapist-settings',
  URL_CHAT_COMMAND_UPDATE_USER_NAME: '/chat/commands/update-user-name',
  URL_CHAT_COMMAND_UPDATE_USER_PICTURE: '/chat/commands/update-user-picture',
  URL_BILLING_QUERY_GET_USERS: '/billing/queries/get-billing-users',
  URL_BILLING_QUERY_GET_EVENTS: '/billing/queries/get-billing-events',
  URL_BILLING_QUERY_GET_CHECKOUT_LINK: '/billing/queries/get-checkout-link',
  URL_AI_COMMAND_GENERATE_SUGGESTION: '/ai/commands/generate-suggestion',
  URL_AI_COMMAND_GENERATE_SUMMARY: '/ai/commands/generate-summary',
  URL_AI_QUERY_GET_SUMMARIES: '/ai/queries/get-summaries',
  URL_THERAPIST_QUERY_GET_THERAPIST: '/therapist/queries/get-therapist', 
  URL_THERAPIST_QUERY_GET_THERAPIST_FROM_CLIENT: '/therapist/queries/get-therapist-from-client',
  URL_THERAPIST_UPDATE_THERAPIST_DESCRIPTION: '/therapist/commands/set-description',
  URL_DASHBOARD_THERAPIST_QUERY_GET_TOTAL_ACTIVE_USERS: '/therapist/dashboard/queries/get-total-active-users',
  URL_DASHBOARD_THERAPIST_QUERY_GET_TOTAL_CANCELLED_USERS: '/therapist/dashboard/queries/get-total-cancelled-users',
  URL_DASHBOARD_THERAPIST_QUERY_GET_MONTHLY_NEW_USERS: '/therapist/dashboard/queries/get-monthly-new-users',
  URL_DASHBOARD_THERAPIST_QUERY_GET_MONTHLY_SESSIONS: '/therapist/dashboard/queries/get-monthly-sessions',
  URL_DASHBOARD_THERAPIST_QUERY_GET_MONTHLY_RENEWALS: '/therapist/dashboard/queries/get-monthly-renewals',
  URL_DASHBOARD_THERAPIST_QUERY_GET_MONTHLY_CANCELLED_USERS: '/therapist/dashboard/queries/get-monthly-cancelled-users',
  URL_DASHBOARD_THERAPIST_QUERY_GET_TOTAL_NEW_SUBSCRIBERS: '/therapist/dashboard/queries/get-total-new-subscribers',
  URL_DASHBOARD_THERAPIST_QUERY_GET_TOTAL_SESSIONS: '/therapist/dashboard/queries/get-total-sessions',
  URL_ACTIVITIES_QUERY_GET_CATEGORIES: '/activities/queries/get-categories',
  URL_ACTIVITIES_QUERY_GET_BLUEPRINTS: '/activities/queries/get-blueprints',
  URL_ACTIVITIES_QUERY_GET_VERSIONS: '/activities/queries/get-versions',
  URL_ACTIVITIES_QUERY_GET_VERSION: '/activities/queries/get-version',
  URL_ACTIVITIES_COMMAND_CREATE_BLUEPRINT: '/activities/commands/create-blueprint',
  URL_ACTIVITIES_QUERY_GET_ACTIVITY: '/activities/queries/get-activity',
  URL_ACTIVITIES_COMMAND_LIKE_BLUEPRINT: '/activities/commands/like-blueprint',
  URL_ACTIVITIES_COMMAND_BOOKMARK_BLUEPRINT: '/activities/commands/bookmark-blueprint',
  URL_ACTIVITIES_COMMAND_SEND_ACTIVITY: '/activities/commands/send-activity',
  URL_ACTIVITIES_COMMAND_SUBMIT_ACTIVITY: '/activities/commands/submit-activity',
  URL_ACTIVITIES_COMMAND_UPDATE_ACTIVITY: '/activities/commands/update-activity',
  URL_ACTIVITIES_GET_ACTIVITIES: '/activities/queries/get-activities',
  URL_SESSIONS_QUERY_GET_SESSIONS: '/sessions/queries/get-sessions',
  URL_SESSIONS_QUERY_GET_PENDING_SESSIONS: '/sessions/queries/get-pending-sessions',
  URL_SESSIONS_COMMAND_UPDATE_SESSION: '/sessions/commands/update-session',
}
