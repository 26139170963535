<template>
  <div class="file-input-wrapper">
    <!-- Hidden native file input -->
    <input
      type="file"
      :class="props.class"
      @change="handleFileChange"
      :accept="acceptedFileTypes"
      :multiple="props.multiple"
      :id="props.id"
      ref="inputElement"
      class="d-none"
    />
    
    
    <!-- Selected Files List with drag and drop -->
    <div 
      v-if="props.multiple || selectedFiles.length === 0"
      class="upload-area mb-3"
      :class="{ 'is-dragging': isDragging, 'is-invalid': showError }"
      @dragenter.prevent="handleDragEnter"
      @dragleave.prevent="handleDragLeave"
      @dragover.prevent
      @drop.prevent="handleDrop"
    >
      <div class="upload-area-content">
        <i class="bi bi-cloud-upload display-5"></i>
        <p class="mb-0 text-sm">{{ i18n('dragAndDrop') }}</p>
        <small class="text-muted">{{ i18n('or') }}</small>
        <button 
          type="button" 
          class="btn btn-explorer text-sm"
          @click="triggerFileInput"
        >
          {{ i18n('browseFiles') }}
        </button>
      </div>
    </div>

    <!-- Selected Files List -->
    <div 
      v-if="selectedFiles.length > 0" 
      class="selected-files"
      :class="{ 'is-invalid': showError }"
    >
      <div v-for="(file, index) in selectedFiles" :key="index" 
           class="selected-file d-flex align-items-center justify-content-between mb-2">
        <div class="d-flex align-items-center overflow-hidden">
          <i class="bi bi-file-earmark me-2"></i>
          <div class="file-info overflow-hidden">
            <div class="file-name text-truncate text-sm">{{ file.name }}</div>
            <small class="file-size text-muted text-sm">{{ formatFileSize(file.size) }}</small>
          </div>
        </div>
        <button 
          type="button" 
          class="btn btn-outline-danger btn-sm ms-2" 
          @click="removeFile(index)"
          aria-label="Remove file"
        >
          <i class="bi bi-x"></i>
        </button>
      </div>
    </div>

    <!-- Validation Messages -->
    <div v-if="showError" class="invalid-feedback d-block mt-2">
      {{ validationMessage }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, computed } from 'vue'
import { useI18n } from '@/utils/i18n'

const props = defineProps({
  id: {
    type: String,
    default: `file-${Math.random().toString(36).substr(2, 9)}`,
  },
  multiple: {
    type: Boolean,
    default: false,
  },
  maxFileSize: {
    type: Number,
    default: 20 * 1024 * 1024, // 20 MB
  },
  maxFiles: {
    type: Number,
    default: 5,
  },
  acceptedFileTypes: {
    type: String,
    default: '*/*',
  },
  class: {
    type: String,
    default: '',
  }
})

const emit = defineEmits(['update:modelValue', 'files-changed'])
const inputElement = ref<HTMLInputElement | null>(null)
const selectedFiles = ref<File[]>([])
const validationMessage = ref('')
const isDragging = ref(false)
const showError = computed(() => validationMessage.value !== '')

const getFileTypesText = () => {
  if (props.acceptedFileTypes === '*/*') return i18n('allFilesAllowed')
  
  const types = props.acceptedFileTypes.split(',').map(type => {
    if (type.startsWith('.')) return type.toUpperCase()
    if (type.endsWith('/*')) return type.replace('/*', ' files')
    return type
  })
  
  return `${i18n('allowedTypes')}: ${types.join(', ')}`
}

const validateFiles = (newFiles: File[]): boolean => {
  validationMessage.value = ''
  
  const totalFiles = props.multiple 
    ? selectedFiles.value.length + newFiles.length 
    : newFiles.length

  if (totalFiles > props.maxFiles) {
    validationMessage.value = i18n('tooManyFiles', { max: props.maxFiles })
    return false
  }

  for (const file of newFiles) {
    if (file.size > props.maxFileSize) {
      validationMessage.value = i18n('fileTooLarge', { 
        filename: file.name, 
        maxSize: formatFileSize(props.maxFileSize) 
      })
      return false
    }

    if (props.acceptedFileTypes !== '*/*') {
      const fileType = file.type
      const acceptedTypes = props.acceptedFileTypes.split(',')
      const isValidType = acceptedTypes.some(type => {
        if (type.endsWith('/*')) {
          return fileType.startsWith(type.replace('/*', ''))
        }
        return type === fileType || type.startsWith('.') && file.name.toLowerCase().endsWith(type.toLowerCase())
      })

      if (!isValidType) {
        validationMessage.value = i18n('invalidFileType', { filename: file.name })
        return false
      }
    }
  }

  return true
}

const triggerFileInput = () => {
  inputElement.value?.click()
}

const handleFileChange = (event: Event) => {
  const input = event.target as HTMLInputElement
  if (!input.files) return
  
  handleFiles(Array.from(input.files))
}

const handleFiles = (newFiles: File[]) => {
  if (validateFiles(newFiles)) {
    if (props.multiple) {
      selectedFiles.value = [...selectedFiles.value, ...newFiles]
    } else {
      selectedFiles.value = [newFiles[0]]
    }
    emit('files-changed', selectedFiles.value)
  }
  
  // Always clear input to allow selecting the same file again
  if (inputElement.value) {
    inputElement.value.value = ''
  }
}

const handleDragEnter = (e: DragEvent) => {
  isDragging.value = true
}

const handleDragLeave = (e: DragEvent) => {
  const rect = (e.currentTarget as HTMLElement).getBoundingClientRect()
  if (
    e.clientX >= rect.left &&
    e.clientX <= rect.right &&
    e.clientY >= rect.top &&
    e.clientY <= rect.bottom
  ) {
    return
  }
  isDragging.value = false
}

const handleDrop = (e: DragEvent) => {
  isDragging.value = false
  if (!e.dataTransfer?.files) return
  
  handleFiles(Array.from(e.dataTransfer.files))
}

const removeFile = (index: number) => {
  selectedFiles.value = selectedFiles.value.filter((_, i) => i !== index)
  emit('files-changed', selectedFiles.value)
}

const formatFileSize = (bytes: number): string => {
  if (bytes === 0) return '0 Bytes'
  const k = 1024
  const sizes = ['Bytes', 'KB', 'MB', 'GB']
  const i = Math.floor(Math.log(bytes) / Math.log(k))
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + ' ' + sizes[i]
}

// i18n messages
const i18nLocales = {
  en_US: {
    uploadFile: 'Upload File',
    uploadFiles: 'Upload Files',
    browseFiles: 'Browse Files',
    dragAndDrop: 'Drag and drop files here',
    or: 'or',
    allowedTypes: 'Allowed types',
    allFilesAllowed: 'All file types allowed',
    tooManyFiles: 'Maximum {max} files allowed',
    fileTooLarge: '{filename} is too large. Maximum size is {maxSize}',
    invalidFileType: '{filename} has an invalid file type',
  },
  es_ES: {
    uploadFile: 'Subir Archivo',
    uploadFiles: 'Subir Archivos',
    browseFiles: 'Explorar Archivos',
    dragAndDrop: 'Arrastra y suelta archivos aquí',
    or: 'o',
    allowedTypes: 'Tipos permitidos',
    allFilesAllowed: 'Todos los tipos de archivo permitidos',
    tooManyFiles: 'Máximo {max} archivos permitidos',
    fileTooLarge: '{filename} es demasiado grande. El tamaño máximo es {maxSize}',
    invalidFileType: '{filename} tiene un tipo de archivo inválido',
  },
  ca_ES: {
    uploadFile: 'Pujar Arxiu',
    uploadFiles: 'Pujar Arxius',
    browseFiles: 'Explorar Arxius',
    dragAndDrop: 'Arrossega i deixa anar els arxius aquí',
    or: 'o',
    allowedTypes: 'Tipus permesos',
    allFilesAllowed: 'Tots els tipus de fitxer permesos',
    tooManyFiles: 'Màxim {max} arxius permesos',
    fileTooLarge: '{filename} és massa gran. La mida màxima és {maxSize}',
    invalidFileType: '{filename} té un tipus de fitxer invàlid',
  },
}

const { i18n } = useI18n(i18nLocales)
</script>

<style scoped lang="scss">
.file-input-wrapper {
  width: 100%;
  .upload-area {
    border: 2px dashed #ced4da;
    border-radius: 0.5rem;
    padding: 2rem;
    text-align: center;
    transition: all 0.2s ease;
    
    &.is-dragging {
      border-color: #0d6efd;
      background-color: rgba(13, 110, 253, 0.05);
    }
    
    &.is-invalid {
      border-color: var(--bs-danger);
    }
    
    .upload-area-content {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 0.5rem;
      
      i {
        color: #6c757d;
      }
    }
  }

  .selected-files {
    border-radius: 0.5rem;
    
    &.is-invalid {
      border: 1px solid var(--bs-danger);
    }
    
    .selected-file {
      background-color: $grey-900;
      border-radius: 50px;
      padding: 5px 20px;
      
      .file-info {
        max-width: calc(100% - 4rem);
      }
      
      .file-name {
        font-weight: 500;
      }
      
      .file-size {
        font-size: 0.875rem;
      }
    }
  }
}
.btn-explorer {
  background-color: $white;
  color: $primary;
  border-radius: 50px;
  padding: 0px 20px;
  
  &:hover {
    background-color: $green-500;
  }
}
</style>