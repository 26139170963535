<template>
  <OffCanvas
    :id="props.id"
    :isVisible="props.isVisible"
    class="w-100"
  >
  <div class="d-flex flex-column h-100 w-100">
    <div v-if="!activityBlueprintsStore.loadingCreatingBlueprint" class="h-100 w-100">
      <FormCreateActivity
        @onSubmit="onSubmit"
      />
    </div>
    <div v-else>
      <div class="d-flex justify-content-center align-items-center h-100">
        <LoadingSpinner />
      </div>
    </div>
    
  </div>
    
  </OffCanvas>
</template>

<script setup lang="ts">
import OffCanvas from "@/components/atoms/OffCanvas.vue";
import { useActivityBlueprintsStore } from "@/stores/activities/activity_blueprints";
import { useOffcanvasStore } from "@/stores/offcanvas";
import FormCreateActivity from "./FormCreateActivity.vue";
import type { CommandCreateActivityBlueprintType } from "../../interface";
import { useNotificationsStore } from "@/stores/notifications";
import LoadingSpinner from "@/components/atoms/LoadingSpinner.vue";
import { useI18n } from "@/utils/i18n";
const offcanvasStore = useOffcanvasStore();
const activityBlueprintsStore = useActivityBlueprintsStore();
const notificationsStore = useNotificationsStore();
const props = defineProps<{
  id: string;
  isVisible: boolean;
}>();

const i18nLocales = {
  es_ES: {
    notificationSucceed: 'Actividad creada con éxito',
    notificationError: 'Error al crear la actividad',
  },
  en_US: {
    notificationSucceed: 'Activity created successfully',
    notificationError: 'Error creating activity',
  },
  ca_ES: {
    notificationSucceed: 'Activitat creada amb èxit',
    notificationError: 'Error al crear l\'activitat',
  },

}
const {i18n} = useI18n(i18nLocales);

const onSubmit = async (form: CommandCreateActivityBlueprintType) => {
    try {
    await activityBlueprintsStore.createBlueprint(form);
    notificationsStore.addNotification(i18n('notificationSucceed'), 'success');
    offcanvasStore.toggleOffcanvas('createBlueprint');
  } catch (error) {
    notificationsStore.addNotification(i18n('notificationError'), 'error');
  }
}

</script>

<style scoped lang="scss">
h4 {
  color: $grey-150;
  margin-top: 20px;
  margin-bottom: 10px;
}
.offcanvas-title-input {
  @extend .text-xxl--semibold;
  border: none;
  margin-bottom: 10px;
}
.form-group {
  margin-bottom: 3px;
  display: flex;
  align-items: left;
  @media screen and (max-width: 768px) {
    flex-direction: column;
    margin-bottom: 30px;
    padding-left: 0px !important;
    
  }
  & > label {
    @extend .text-md--semibold;
    color: $grey-150;
    min-width: 200px;
    @media screen and (max-width: 768px) {
      padding-left: 12px;
      
    }
  }
  & > input {
    @extend .text-md--thin;
    width: 100%;
    border: none;
    &:not(:placeholder-shown) {
      background-color: transparent;
    }
  }
  & > textarea {
    @extend .text-md--regular;
    width: 100%;
    height: 100%;
    border: none;
    height: 100px;
    padding: 0px;
  }
}
</style>