<template>
<div :class="[`loading-container`, classColor, sizeClass]">
  <div v-if="props.message!==undefined" class="loading-text" :class="sizeTextClass">{{ props.message}}</div>
      <div class="spinner-border" :class="sizeSpinnerClass" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>
</template>
<script setup lang="ts">
import { computed} from 'vue';

interface InputProps {
  message?: string | undefined;
  size?: 'small' | 'medium' | 'large';
  color?: 'primary' | 'secondary' | 'orange' | 'green' | 'red' | 'blue' | 'yellow' | 'grey' | 'grey-500';
}

const props: InputProps = withDefaults(defineProps<InputProps>(), {
  message: undefined,
  size: 'large',
  color: 'primary'
});

const classColor = computed(() => ({
  'loading-container__primary': props.color === 'primary',
  'loading-container__secondary': props.color === 'secondary',
  'loading-container__orange': props.color === 'orange',
  'loading-container__green': props.color === 'green',
  'loading-container__red': props.color === 'red',
  'loading-container__blue': props.color === 'blue',
  'loading-container__yellow': props.color === 'yellow',
  'loading-container__grey': props.color === 'grey',
  'loading-container__grey-500': props.color === 'grey-500',
}));

const sizeSpinnerClass = computed(() => ({
  'spinner-border-sm': props.size === 'small',
  'spinner-border-lg': props.size === 'large',
}));

const sizeTextClass = computed(() => ({
  'loading-text__small': props.size === 'small',
  'loading-text__large': props.size === 'large',
}));
const sizeClass = computed(() => ({
  'loading-container__small': props.size === 'small',
  'loading-container__large': props.size === 'large',
}));

</script>
<style scoped lang="scss">
.loading-container {
  color: $primary;
  display: flex;

  &__small {
    flex-direction: row;
    flex-shrink: 1;
    width: auto;
    height: auto;
  }
  &__large {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 100%;
    width: 100%;
  }

  &__primary {
    color: $primary;
  }
  &__secondary {
    color: $secondary;
  }
  &__orange {
    color: $orange-100;
  }
  &__green {
    color: $green-100;
  }
  &__red {
    color: $red-100;
  }
  &__blue {
    color: $blue-100;
  }
  &__yellow {
    color: $yellow-100;
  }
  &__grey {
    color: $grey-100;
  }
  &__grey-500 {
    color: $grey-500;
  }
}

.loading-text {
  &__small {
    font-size: 14px;
  }
  &__large {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}
</style>