<template>
  <form
    :id="props.formId"
    class="needs-validation"
    :class="{ 'was-validated': wasValidated }"
    novalidate
    @input="validateForm"
    @submit.prevent="handleSubmit"
    ref="form"
  >
    <slot></slot>
  </form>
</template>

<script setup lang="ts">
import { ref } from 'vue'

const props = defineProps({
  formId: {
    type: String,
    default: 'base-form',
  },
  valid: Boolean,
})

const emits = defineEmits(['form-valid', 'form-invalid', 'update:valid'])

const wasValidated = ref(false)
const form = ref<HTMLFormElement | null>(null)
const isValid = ref(false)

const validateForm = () => {
  if (form.value) {
    isValid.value = form.value.checkValidity()
    emits('update:valid', isValid.value)
  }
}

const handleSubmit = () => {
  wasValidated.value = true
  validateForm()
  if (isValid.value) {
    emits('form-valid')
  } else {
    emits('form-invalid')
  }
}
</script>