<template>
  <div
    ref="container"
    class="position-fixed bottom-0 end-0 p-3"
    style="z-index: 1050"
  >
    <div v-if="notifications">
      <div v-for="item in notifications" :key="item.id">
        <div :id="item.id" class="toast" role="alert" aria-live="assertive" aria-atomic="true" data-bs-delay="3000">
          <div class="toast-body d-flex justify-content-between" :class="item.type">
            <slot></slot>
            <i class="bi bi-info-circle me-2"></i>
            <div class="me-auto toast-text">{{ item.message }}</div>
            <button type="button" class="btn-close" :class="item.type" data-bs-dismiss="toast" aria-label="Close"></button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from 'vue';
import { useNotificationsStore } from '@/stores/notifications';
import { Toast } from 'bootstrap';

const notificationsStore = useNotificationsStore();
const notifications = ref(notificationsStore.notifications);

watch(notificationsStore.notifications, (notifications) => {
  const lastNotification = notifications[notifications.length - 1];
  setTimeout(() => {
    const notificationToast = document.getElementById(lastNotification.id);
      if (notificationToast && lastNotification.show) {
        const toast = new Toast(notificationToast);
        toast.show();
      }
      // notificationToast?.addEventListener('hidden.bs.toast', () => {
      //   notificationsStore.hideNotification(lastNotification.id);
      // });
    }, 10);
});

</script>

<style scoped lang="scss">
.toast{
  border: none;
  margin-bottom: 5px;
  background-color: $green-400;
}
.toast-body{
  padding-left: 15px;
  padding-top: 8px;
  padding-bottom: 8px;
  border-radius: 18px;
}
.toast-text{
  @extend .text-sm--bold;

}
.error{
  background-color: $red-100;
  color: $white;
}
.info{
  background-color: $blue-100;
  color: $white;
}
.success{
  background-color: $green-100;
  color: white;
}
.warning{
  background-color: $yellow-400;
  color: $yellow-100;
}

</style>
