<template>
  <button 
    class="pill text-xxs--regular text-truncate"
    :style="width ? `max-width: ${width}px` : ''"
    :class="classColor" 
    data-bs-toggle="tooltip" 
    data-bs-placement="top"
    data-bs-html="true"
    :title="text" 
    >
    {{ props.text }}
  </button>
</template>


<script setup lang="ts">
import { onMounted, computed} from 'vue';
import { Tooltip } from 'bootstrap';


interface InputProps {
  text?: string;
  color?: 'primary' | 'secondary' | 'orange' | 'green' | 'red' | 'blue' | 'yellow';
  width?: string;
}
const props: InputProps = defineProps<InputProps>()

const classColor = computed(() => ({
  'pill__primary': props.color === 'primary',
  'pill__secondary': props.color === 'secondary',
  'pill__orange': props.color === 'orange',
  'pill__green': props.color === 'green',
  'pill__red': props.color === 'red',
  'pill__blue': props.color === 'blue',
  'pill__yellow': props.color === 'yellow',
}));

onMounted(() => {
  const tooltipTriggerList = document.querySelectorAll('[data-bs-toggle="tooltip"]');
  tooltipTriggerList.forEach((tooltipTriggerEl) => {
    new Tooltip(tooltipTriggerEl);
  });
});
</script>

<style scoped lang="scss">
.pill {
  display: inline-block;
  max-width: 40px;
  color: $grey-900;
  border-radius: 10px;
  border: none;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 0px;
  padding-bottom: 0px;
  &__primary {
    background-color: rgba($primary, 0.5);
  }
  &__secondary {
    background-color: rgba($secondary, 0.5);
    color: $grey-100
  }
  &__orange {
    background-color: rgba($orange-100, 0.5);
  }
  &__green {
    background-color: rgba($green-100, 0.5);
  }
  &__red {
    background-color: rgba($red-100, 0.5);
  }
  &__blue {
    background-color: rgba($blue-100, 0.5);
  }
  &__yellow {
    background-color: rgba($yellow-100, 0.5);
  }
}
</style>