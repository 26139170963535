<template>
  <div
    class="card"
    :style="`width: ${props.width}; height: ${props.height}`"
    :class="{ 'clickable': props.clickable , 'selected': props.selected }"
    @click="handleClick"
  >
    <div class="header-card">
      <slot name="header"></slot>
    </div>
    <div v-if="isLoaded" :class="hasHeaderSlot ? 'card-body': ''">
      <slot></slot>
    </div>
    <div v-else-if="props.loading" class="loading-card">
      <LoadingSpinner />
    </div>
    <div v-else-if="props.error" class="error-card">
      {{ i18n('notLoaded') }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watchEffect, computed, useSlots } from 'vue'
import { useI18n } from "@/utils/i18n";

import LoadingSpinner from './LoadingSpinner.vue';
const emits = defineEmits([
  'click'
])
const props = defineProps({
  height: {
    type: String,
    default: '100%'
  },
  width: {
    type: String,
    default: '100%'
  },
  loading: {
    type: Boolean,
    default: false
  },
  error: {
    type: Boolean,
    default: false
  },
  clickable: {
    type: Boolean,
    default: false
  },
  selected: {
    type: Boolean,
    default: false
  }
})

const isLoaded = ref(!props.loading && !props.error)

watchEffect(() => {
  isLoaded.value = !props.loading && !props.error
})

const i18nLocales = {
  es_ES: {
    notLoaded: '🥸 No hemos podido cargar los datos..',
  },
  en_US: {
    notLoaded: '🥸 We couldn\'t load the data..',
  },
  ca_ES: {
    notLoaded: '🥸 No hem pogut carregar les dades..',
  },
}
const { i18n } = useI18n(i18nLocales);

const handleClick = () => {
  if (props.clickable) {
    emits('click')
  }
}

const slots = useSlots();
const hasHeaderSlot = computed(() => !!slots.header);
</script>

<style scoped lang="scss">
.card {
  border-radius: 15px;
  padding: 10px 20px;
  height: fit-content;
  display: flex;
  min-width: 300px;
  min-height: 100px;
  transition: border-color 0.3s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);

  &:hover {
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.1);
  }

  &.clickable {
    cursor: pointer;

    &:hover {
      border-color: $green-200;
      box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
    }
  }
  &.selected {
    border-color: $green-200;
    background-color: $green-400;
  }

  @media screen and (max-width: 768px) {
    min-width: 100%;
    min-height: 100%;
    padding: 5px 10px;
  }
}

.loading-card {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 0px;
  height: 100%;
}

.error-card {
  @extend .text-md--regular;
  white-space: pre-wrap;
  color: $orange-200;

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 40px 20px;
  height: 100%;
}

.card-body {
  padding: 20px 0px 0px 0px;

  @media screen and (max-width: 768px) {
    padding: 10px 0px;
  }
}
</style>