<template>
    <input v-if="!props.textArea"
      type="text"
      :class="['form-control', props.class, { 'is-invalid': showError }]"
      v-model="localValue"
      @blur="handleBlur"
      @focus="handleFocus"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @input="handleInput"
      :disabled="props.disabled"
      required
      :pattern="patternTxt"
      minlength="2"
      :placeholder="props.placeHolder"
      data-bs-placement="top"
      :title="props.errorMessage ? props.errorMessage : i18n('errorMessage')"
      ref="inputElement"
    />
    <textarea v-else
      :class="['form-control', props.class, { 'is-invalid': showError }]"
      v-model="localValue"
      @blur="handleBlur"
      @focus="handleFocus"
      @mouseenter="handleMouseEnter"
      @mouseleave="handleMouseLeave"
      @input="handleInput"
      required
      :disabled="props.disabled"
      :pattern="patternTxt"
      minlength="2"
      :placeholder="props.placeHolder"
      data-bs-placement="top"
      :title="props.errorMessage ? props.errorMessage : i18n('errorMessage')"
      ref="inputElement"
    >
    </textarea>
</template>

<script setup lang="ts">
import { ref, watch, onMounted, onBeforeUnmount, computed } from 'vue'
import { useI18n } from '@/utils/i18n'
import { Tooltip } from 'bootstrap'

const props = defineProps({
  id: {
    type: String,
    default: `${Math.random().toString(36).substr(2, 9)}`,
  },
  modelValue: {
    type: String,
    default: '',
  },
  placeHolder: {
    type: String,
    required: false,
  },
  errorMessage: {
    type: String,
    required: false,
  },
  textArea : {
    type: Boolean,
    default: false,
  },
  class: {
    type: String,
    default: '',
  },
  disabled: {
    type: Boolean,
    default: false,
  }
})

const emit = defineEmits(['update:modelValue', 'input'])
const inputElement = ref<HTMLElement | null>(null)
let tooltip: Tooltip | null = null

const localValue = ref(props.modelValue)
const touched = ref(false)
const patternTxt = '.*'
const isValid = computed(() => {
  if (localValue.value === '') {
    return false // Empty field is invalid due to required attribute
  }
  const pattern = new RegExp(patternTxt)
  return pattern.test(localValue.value)
})

const showError = computed(() => {
  return touched.value && !isValid.value
})

const handleInput = () => {
  if (touched.value && tooltip) {
    if (!isValid.value) {
      tooltip.enable()
    } else {
      tooltip.disable()
    }
  }
  emit('input', localValue.value)
}

const handleBlur = () => {
  touched.value = true
  if (tooltip) {
    tooltip.hide()
    if (!isValid.value) {
      tooltip.enable()
    } else {
      tooltip.disable()
    }
  }
}

const handleFocus = () => {
  if (showError.value && tooltip) {
    tooltip.show()
  }
}

const handleMouseEnter = () => {
  if (showError.value && tooltip) {
    tooltip.show()
  }
}

const handleMouseLeave = () => {
  if (tooltip) {
    tooltip.hide()
  }
}

watch(localValue, (newValue) => {
  emit('update:modelValue', newValue)
})
watch(() => props.modelValue, (newValue) => {
  localValue.value = newValue
})

const i18nLocales = {
  en_US: {
    errorMessage: 'Invalid Field',
  },
  es_ES: {
    errorMessage: 'Campo inválido',
  },
  ca_ES: {
    errorMessage: 'Camp invàlid',
  },
}

const { i18n } = useI18n(i18nLocales)

onMounted(() => {
  if (inputElement.value) {
    tooltip = new Tooltip(inputElement.value, {
      trigger: 'manual',
      customClass: 'error-tooltip'
    })
  }
})

onBeforeUnmount(() => {
  if (tooltip) {
    tooltip.dispose()
  }
})
</script>

<style scoped lang="scss">
.error-tooltip {
  .tooltip-inner {
    background-color: var(--bs-danger);
  }
  .tooltip-arrow::before {
    border-top-color: var(--bs-danger);
  }
}
.form-control {
  border: none;
  margin-top: 4px
}
.form-control:disabled {
  background-color: transparent;
}
</style>