<template>
  <!-- Template remains the same -->
  <div class="search-select" :class="{ 'vertical': props.vertical }">
    <div class="title-container" v-if="props.infoFilter">
      <InfoIcon v-if="props.infoFilter" :text="props.infoFilter" class="me-2"/>
      <span class="text-sm--regular title-text">{{ props.filterVariable.text }}</span>
    </div>
    <div class="choices-outer-container" :class="{ 
      'has-scroll': hasScroll,
      'vertical': props.vertical 
    }" :style="{ maxHeight: props.maxHeight }">
      <!-- Scroll buttons and content remain the same -->
      <button 
        v-if="props.vertical"
        class="scroll-button scroll-up" 
        @click="scrollUp"
        v-show="canScrollUp"
        aria-label="Scroll up"
      >
        <span class="arrow-up">›</span>
      </button>
      <button 
        v-else
        class="scroll-button scroll-left" 
        @click="scrollLeft"
        v-show="canScrollLeft"
        aria-label="Scroll left"
      >
        <span class="arrow-left">‹</span>
      </button>
      
      <div class="choices-container" 
        ref="scrollContainer" 
        @scroll="handleScroll"
        :class="{ 'vertical': props.vertical }"
      >
        <div class="choices-wrapper" :class="{ 'vertical': props.vertical }">
          <div v-for="choice in props.choices" 
            :key="choice.value" 
            class="choice-item"
            :class="{ 'vertical': props.vertical }"
          >
            <input
              type="checkbox"
              class="btn-check"
              autocomplete="off"
              :id="`${choice.value}--${props.filterVariable.value}`"
              :checked="isChecked(choice.value)"
              @click="handleSelect"
            >
            <label class="btn btn-switcher" :for="`${choice.value}--${props.filterVariable.value}`">
              {{ choice.text }}
            </label>
          </div>
        </div>
      </div>

      <button 
        v-if="props.vertical"
        class="scroll-button scroll-down" 
        @click="scrollDown"
        v-show="canScrollDown"
        aria-label="Scroll down"
      >
        <span class="arrow-down">›</span>
      </button>
      <button 
        v-else
        class="scroll-button scroll-right" 
        @click="scrollRight"
        v-show="canScrollRight"
        aria-label="Scroll right"
      >
        <span class="arrow-right">›</span>
      </button>
    </div>
  </div>
</template>

<script setup lang="ts">
import InfoIcon from '@/components/molecules/InfoIcon.vue';
import { defineProps, defineEmits, ref, withDefaults, onMounted } from 'vue';

const emit = defineEmits(['handleInput']);
const scrollContainer = ref<HTMLElement | null>(null);
const hasScroll = ref(false);
const canScrollLeft = ref(false);
const canScrollRight = ref(false);
const canScrollUp = ref(false);
const canScrollDown = ref(false);

interface SelectChoices {
  text?: string;
  value: string;
}

interface SelectProps {
  filterVariable: SelectChoices;
  choices: SelectChoices[];
  selectedValues?: string[];
  infoFilter?: string;
  resetFilterChoice?: string;
  multiple?: boolean;
  vertical?: boolean;
  maxHeight?: string;
}

const props = withDefaults(defineProps<SelectProps>(), {
  selectedValues: () => ['all'],
  resetFilterChoice: 'all',
  multiple: true,
  vertical: false,
  maxHeight: '300px'
});

const selected = ref(props.selectedValues ?? []);
let selectedValue = '';

const isChecked = (value: string) => {
  return selected.value.includes(value);
};

const updateScrollButtons = () => {
  if (!scrollContainer.value) return;
  
  if (props.vertical) {
    const { scrollTop, scrollHeight, clientHeight } = scrollContainer.value;
    hasScroll.value = scrollHeight > clientHeight;
    canScrollUp.value = scrollTop > 0;
    canScrollDown.value = scrollTop < (scrollHeight - clientHeight);
  } else {
    const { scrollLeft, scrollWidth, clientWidth } = scrollContainer.value;
    hasScroll.value = scrollWidth > clientWidth;
    canScrollLeft.value = scrollLeft > 0;
    canScrollRight.value = scrollLeft < (scrollWidth - clientWidth);
  }
};

const handleScroll = () => {
  updateScrollButtons();
};

const scrollLeft = () => {
  if (!scrollContainer.value) return;
  scrollContainer.value.scrollBy({ left: -200, behavior: 'smooth' });
};

const scrollRight = () => {
  if (!scrollContainer.value) return;
  scrollContainer.value.scrollBy({ left: 200, behavior: 'smooth' });
};

const scrollUp = () => {
  if (!scrollContainer.value) return;
  scrollContainer.value.scrollBy({ top: -100, behavior: 'smooth' });
};

const scrollDown = () => {
  if (!scrollContainer.value) return;
  scrollContainer.value.scrollBy({ top: 100, behavior: 'smooth' });
};

const handleSelect = (payload: Event) => {
  const target = payload.target as HTMLInputElement;
  const value = target.id.split('--')[0];
  const returnValue = target.checked;
  
  // Store current scroll position
  const currentScroll = props.vertical 
    ? scrollContainer.value?.scrollTop || 0
    : scrollContainer.value?.scrollLeft || 0;

  if (props.multiple) {
    if (value === props.resetFilterChoice) {
      selected.value = [props.resetFilterChoice];
    } else {
      selected.value = selected.value.filter((item) => item !== props.resetFilterChoice);
      if (returnValue) {
        selected.value.push(value);
      } else {
        selected.value = selected.value.filter((item) => item !== value);
      }
    }

    if (selected.value.length === 0) {
      selected.value.push(props.resetFilterChoice || 'all');
    }
    
    const valueAsString = selected.value.map((item) => `'${item}'`).join(',');
    selectedValue = `(${valueAsString})`;
  } else {
    selected.value = returnValue ? [value] : [];
    selectedValue = selected.value[0] || '';
  }
  emit('handleInput', props.filterVariable.value, selectedValue);
  
  // Restore scroll position
  setTimeout(() => {
    if (scrollContainer.value) {
      if (props.vertical) {
        scrollContainer.value.scrollTop = currentScroll;
      } else {
        scrollContainer.value.scrollLeft = currentScroll;
      }
    }
  }, 0);
};

onMounted(() => {
  if (scrollContainer.value) {
    if (props.vertical) {
      scrollContainer.value.scrollTop = 0;
    } else {
      scrollContainer.value.scrollLeft = 0;
    }
    updateScrollButtons();
  }

  const resizeObserver = new ResizeObserver(() => {
    updateScrollButtons();
  });

  if (scrollContainer.value) {
    resizeObserver.observe(scrollContainer.value);
  }
});
</script>
<style scoped lang="scss">
.search-select {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  max-width: 100%;
  position: relative;
  gap: 10px; // Use gap instead of margin for better spacing
  &.vertical {
    flex-direction: column;
    align-items: stretch;
    gap: 0;
  }
}

.title-container {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  position: sticky;
  left: 0;
  background-color: inherit;
  z-index: 1;
  min-width: fit-content; // Ensure title doesn't wrap
  max-width: max-content; // Prevent excessive width
}

.title-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.choices-outer-container {
  position: relative;
  flex: 1; // Take remaining space
  min-width: 0; // Allow container to shrink below content size
  display: flex;
  align-items: center;

  &.vertical {
    margin-left: 0;
    flex-direction: column;
    width: 100%;
    
    &.has-scroll {
      padding: 10px 0;
    }
  }

  &:not(.vertical).has-scroll {
    padding: 0 30px;
  }
}

.choices-container {
  width: 100%;
  min-width: 0; // Allow container to shrink
  overflow-x: auto;
  overflow-y: hidden;
  -webkit-overflow-scrolling: touch;
  position: relative;
  scrollbar-width: none;
  -ms-overflow-style: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  &.vertical {
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.choices-wrapper {
  display: inline-flex;
  flex-wrap: nowrap;
  padding: 0.5rem 0;
  min-width: min-content;
  width: max-content; // Ensure it takes only needed width

  &.vertical {
    flex-direction: column;
    width: 100%;
    min-width: unset;
  }
}

// Rest of the styles remain the same
.choice-item {
  flex-shrink: 0;
  margin-right: 0.5rem;
  
  &:last-child {
    margin-right: 0;
  }

  &.vertical {
    margin-right: 0;
    margin-bottom: 0.5rem;

    &:last-child {
      margin-bottom: 0;
    }

    .btn-switcher {
      width: 100%;
      text-align: left;
    }
  }
}

.scroll-button {
  position: absolute;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  background-color: $grey-800;
  border: none;
  color: $grey-100;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  z-index: 2;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: $grey-700;
  }

  &.scroll-left {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.scroll-right {
    right: 0;
    top: 50%;
    transform: translateY(-50%);
  }

  &.scroll-up {
    top: 0;
    left: 50%;
    transform: translateX(-50%) rotate(-90deg);
  }

  &.scroll-down {
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) rotate(90deg);
  }
}

.btn-switcher {
  @extend .text-sm--regular;
  border: none;
  padding: 3px 15px;
  background-color: $grey-900;
  border-radius: 25px;
  color: $grey-100;
  cursor: pointer;
  white-space: nowrap;
  text-align: center;
  display: inline-block;

  &:hover {
    background-color: $grey-800;
  }

  &:focus {
    box-shadow: none;
  }
}

.btn-check:checked + .btn-switcher {
  background-color: $green-500;
  color: $primary;
  border: none;

  &:hover {
    background-color: $grey-800;
  }
}
</style>