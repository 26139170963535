<template>
  <div class="media-holder">
    <div v-if="props.type === 'image'" class="media-container image">
      <img :src="props.url" alt="image" loading="lazy" />
    </div>
    <div v-else-if="props.type === 'video'" class="media-container video">
      <video controls>
        <source :src="props.url" type="video/mp4" />
        <p class="fallback-text">Your browser does not support the video tag.</p>
      </video>
    </div>
    <div v-else-if="props.type === 'audio'" class="media-container audio">
      <audio controls>
        <source :src="props.url" type="audio/mpeg" />
        <p class="fallback-text">Your browser does not support the audio tag.</p>
      </audio>
    </div>

    <!-- YouTube Embed -->
    <div v-else-if="isYouTubeUrl" class="media-container urlMedia">
      <p v-if="isLoading" class="loading-text">Loading...</p>
      <iframe
        v-show="!isLoading"
        :src="youtubeEmbedUrl"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
        @load="isLoading = false"
      ></iframe>
    </div>

    <!-- TikTok Embed -->
    <div v-else-if="isTikTokUrl" class="media-container urlMedia">
      <p v-if="isLoading" class="loading-text">Loading...</p>
      <div v-show="!isLoading" v-html="tiktokEmbedHtml"></div>
    </div>

    <!-- Instagram Embed -->
    <div v-else-if="isInstagramUrl" class="media-container urlMedia">
      <p v-if="isLoading" class="loading-text">Loading...</p>
      <div v-show="!isLoading" v-html="instagramEmbedHtml"></div>
    </div>

    <!-- Fallback for regular URLs -->
    <div v-else-if="props.type === 'url'" class="media-container urlMedia">
      <p v-if="isLoading" class="loading-text">Loading...</p>
      <iframe
        v-show="!isLoading"
        :src="props.url"
        frameborder="0"
        allowfullscreen
        @load="isLoading = false"
      ></iframe>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { MediaResponseType } from '../interface';
import { computed, ref, onMounted } from 'vue';

const props = defineProps<MediaResponseType>();

const isLoading = ref(true);

const isYouTubeUrl = computed(() => /(?:youtube\.com|youtu\.be)/.test(props.url));
const isTikTokUrl = computed(() => /(?:tiktok\.com\/.*\/video\/)/.test(props.url));
const isInstagramUrl = computed(() => /(?:instagram\.com\/p\/|instagr\.am\/p\/)/.test(props.url));

const youtubeEmbedUrl = computed(() => {
  if (!isYouTubeUrl.value) return '';
  const videoId = props.url.match(/(?:v=|\/)([a-zA-Z0-9_-]{11})/);
  return videoId ? `https://www.youtube.com/embed/${videoId[1]}` : '';
});

const tiktokEmbedHtml = computed(() => {
  if (!isTikTokUrl.value) return '';
  return `<blockquote class="tiktok-embed" cite="${props.url}" data-video-id="${props.url.split('/video/')[1]}" style="width: 100%;"><a href="${props.url}">Watch TikTok</a></blockquote>`;
});

const instagramEmbedHtml = computed(() => {
  if (!isInstagramUrl.value) return '';
  return `<blockquote class="instagram-media" data-instgrm-captioned data-instgrm-permalink="${props.url}" style="width: 100%;"><a href="${props.url}">View on Instagram</a></blockquote>`;
});

// Load TikTok and Instagram's embed scripts
onMounted(() => {
  if (isTikTokUrl.value && !document.querySelector('#tiktok-embed-script')) {
    const script = document.createElement('script');
    script.id = 'tiktok-embed-script';
    script.src = 'https://www.tiktok.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      isLoading.value = false;
    };
  }

  if (isInstagramUrl.value && !document.querySelector('#instagram-embed-script')) {
    const script = document.createElement('script');
    script.id = 'instagram-embed-script';
    script.src = 'https://www.instagram.com/embed.js';
    script.async = true;
    document.body.appendChild(script);
    script.onload = () => {
      isLoading.value = false;
    };
  }
});
</script>



<style scoped lang="scss">
// Variables
$border-radius: 8px;
$transition-duration: 0.3s;
$shadow-color: rgba(0, 0, 0, 0.1);
$error-color: #ff4444;

// Mixins
@mixin media-base {
  width: 100%;
  height: 100%;
  object-fit: contain;
  transition: all $transition-duration ease;
}

@mixin container-base {
  width: 100%;
  height: 100%;
  border-radius: $border-radius;
  overflow: hidden;
  box-shadow: 0 4px 6px $shadow-color;
}

// Main Container
.media-holder {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
  background-color: rgba(#000, 0.02);
  border-radius: $border-radius;
}

// Media Container Styles
.media-container {
  @include container-base;
  position: relative;
  
  &.image {
    background-color: #fff;
    
    img {
      @include media-base;
      max-height: 600px;
      object-fit: cover;
      
      &:hover {
        transform: scale(1.02);
      }
    }
  }
  
  &.video {
    background-color: #000;
    
    video {
      @include media-base;
      max-height: 480px;
      width: 100%;
      
      &:focus {
        outline: none;
      }
      
      &::cue {
        background-color: rgba(0, 0, 0, 0.8);
        color: white;
      }
    }
  }
  
  &.audio {
    background: linear-gradient(145deg, #f6f6f6, #ffffff);
    padding: 1rem;
    max-width: 500px;
    margin: 0 auto;
    
    audio {
      @include media-base;
      width: 100%;
      
      &::-webkit-media-controls-panel {
        background-color: #f8f9fa;
      }
      
      &::-webkit-media-controls-play-button {
        background-color: #007bff;
        border-radius: 50%;
        transform: scale(1.2);
      }
      
      &:focus {
        outline: none;
      }
    }
  }
}

.loading-text {
  color: gray;
  text-align: center;
  font-size: 1rem;
  padding: 1rem;
}

// Fallback Text
.fallback-text {
  color: $error-color;
  text-align: center;
  padding: 1rem;
  font-size: 0.9rem;
}

// Responsive Design
@media (max-width: 768px) {
  .media-holder {
    padding: 0.5rem;
  }
  
  .media-container {
    &.image img {
      max-height: 400px;
    }
    
    &.video video {
      max-height: 300px;
    }
    
    &.audio {
      max-width: 100%;
    }
  }
}

// Print styles
@media print {
  .media-holder {
    break-inside: avoid;
    page-break-inside: avoid;
  }
  
  .media-container.video,
  .media-container.audio {
    display: none;
  }
}
</style>